<template>
	<div class="main" :class="show ? 'h5main' : ''">		
		<div class="htop"><mtop /></div>
		<div class="demo_box1">
			<div class="box1_top">
				<div class="img">
					<!-- <img src="../assets/indeximg/point.png" alt="" /> -->
				</div>
				<div>
					您的当前位置：
					<span @click="toPage('/index')">网站首页 </span>> 案例展示
				</div>
			</div>
			<div class="box1_bottom">
				<div class="bot_item">
					<div class="tap_title">项目分类</div>
					<div class="tap">
						<div
							:class="{ tab_act: firstIndex == 0 }"
							@click="leftToggle(0)"
							style="margin-left: 20px"
						>
							全部
						</div>
						<div
							v-for="(item, index) in projectCatetitle"
							:key="index"
							@click="leftToggle(item.id)"
							:class="{ tab_act: firstIndex == item.id }"
						>
							{{ item.name }}
						</div>
					</div>
				</div>
				<div class="bot_item" style="border-bottom: none">
					<div class="tap_title">行业分类</div>
					<div class="tap">
						<div
							style="margin-left: 20px"
							:class="{ tab_act: firstIndex1 == 0 }"
							@click="leftToggle1(0)"
						>
							全部
						</div>
						<div
							:class="{ tab_act: firstIndex1 == item.id }"
							@click="leftToggle1(item.id)"
							v-for="(item, index) in workCatetitle"
							:key="index"
						>
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
			<div class="box2">
				<div class="box1_con" v-if="exmList.length > 0">
					<!-- <div class="con_item" @click="showtip">
               <div class="item_img">
                  <img src="../assets/indeximg/con2.png" alt="" />
               </div>
               <div class="item_title">OrientaLab</div>
               <div class="item_con">
                  OrientaLab是一家文化创新工作室，拥有一支富有冒险精神的创意专业团队，致力于创作数字艺术形式的新时代。
               </div>
               <div class="item_box">
                  <div>科技</div>
                  <div>区块链</div>
                  <div>展会网站</div>
               </div>
            </div>
            <div class="con_item" @click="showtip">
               <div class="item_img">
                  <img src="../assets/indeximg/con3.png" alt="" />
               </div>
               <div class="item_title">Aotaverse</div>
               <el-tooltip content="AOTA项目是一个基于ETH NFT系列，具有与游戏Fi兼容的一款藏品游戏。" placement="bottom" effect="light">
               <div class="item_con">
                 AOTA项目是一个基于ETH NFT系列，具有与游戏Fi兼容的一款藏品游戏。
               </div>
               </el-tooltip>
               <div class="item_box">
                  <div>科技</div>
                  <div>区块链</div>
                  <div>游戏</div>
               </div>
            </div>
            <div class="con_item" @click="showtip">
               <div class="item_img">
                  <img src="../assets/indeximg/con4.png" alt="" />
               </div>
               <div class="item_title">丘比特爱情</div>
               <el-tooltip content="丘比特创造了一个独特的爱情元宇宙，让世界各地的每个人都可以探索这个奥秘。" placement="bottom" effect="light">
               <div class="item_con">
                  丘比特创造了一个独特的爱情元宇宙，让世界各地的每个人都可以探索这个奥秘。
               </div>
               </el-tooltip>
               <div class="item_box">
                  <div>区块链</div>
                  <div>游戏</div>
                  
               </div>
            </div> -->
					<div
						class="con_item"
						@click="showtip(item.id)"
						v-for="(item, index) in exmList"
						:key="index"
					>
						<div class="item_img">
							<img :src="item.thumb" alt="" />
						</div>
						<div class="item_title">{{ item.title }}</div>
						<div class="item_con">
							{{ item.description }}
						</div>
						<div class="item_box">
							<div v-for="(items, i) in item.label" :key="i">
								{{ items }}
							</div>
						</div>
					</div>
				</div>
				<div class="box1_cons" v-else>
					<van-empty description="暂无更过案例~" />
				</div>
			</div>
		</div>
		<!-- 分页 -->
		<el-pagination
			v-show="listQuery.total > 0"
			:page-size.sync="listQuery.page_size"
			:current-page.sync="listQuery.page"
			@pagination="getexmList"
			:background="!show"
			class="page"
			layout="prev, pager, next"
			@current-change="handleCurrentChange"
			:total="listQuery.total"
		>
		</el-pagination>

		<!-- "
         
          -->
		<mbottom />
	</div>
</template>

<script>
	import { walletOfOwner, tokenURI } from "@/api/Public";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			return {
				exmList: [],
				projectCatetitle: [],
				workCatetitle: [],
				firstIndex: 0,
				total: 0,
				listQuery: {
					page: 1,
					page_size: 3,
					craft_cate_id: 0,
					project_cate_id: 0,
					is_hot: 0,
					total: 0,
				},
				firstIndex1: 0,
				list: [1, 2, 3, 4, 5, 6],
				active: "",
				show: false,
				name: "",
				formdata: {
					Page: 1,
					PageSize: "20",
				},
				menu: "",
				loading: false,
				finished: false,
				refreshing: false,
				showlist: false,
				mylist: {},
				basemenu: "",
			};
		},
		components: {
			mtop,
			mbottom,
		},
		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
		},
		watch: {
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			// this.getMenu();
			this.getInfo();
			this.getexmList();
		},
		methods: {
			handleCurrentChange(val) {
				console.log(val);
				this.listQuery.page = val;
				this.getexmList();
			},
			getexmList() {
				this.$axios({
					method: "get",
					url: "/v1/casusList",
					params: this.listQuery,
				})
					.then((res) => {
						this.exmList = [];
						this.exmList = res.data.data.list.data;
						this.listQuery.total = res.data.data.list.total;
						console.log(this.listQuery.total);
					})
					.catch(() => {
						console.log("请求失败");
					});
			},
			getInfo() {
				this.$axios({
					method: "get",
					url: "/v1/projectCate",
				})
					.then((res) => {
						this.projectCatetitle = res.data.data.list;
					})
					.catch(() => {
						console.log("请求失败");
					});

				this.$axios({
					method: "get",
					url: "/v1/craftCate",
				})
					.then((res) => {
						this.workCatetitle = res.data.data.list;
					})
					.catch(() => {
						console.log("请求失败");
					});
			},
			showtip(e) {
				//   this.$toast('暂无详情');

				sessionStorage.setItem("anliid", e);
				// this.$router.replace({ path: 'teamdetal?detail='+row });
				this.$router.push({
					name: "gallerypc",
					params: {
						anliid: e,
					},
				});
			},
			leftToggle(e) {
				if (e == this.firstIndex) {
					return false;
				} else {
					this.firstIndex = e;

					this.listQuery.project_cate_id = e;
					this.getexmList();
				}
			},
			leftToggle1(e) {
				if (e == this.firstIndex1) {
					return false;
				} else {
					this.firstIndex1 = e;
					this.listQuery.craft_cate_id = e;
					this.getexmList();
				}
			},
			toPage(e) {
				this.$router.replace({ path: e });
			},

			seach() {
				this.formdata = {
					Name: this.name,
					Page: 1,
					PageSize: "20",
				};
				this.menu = this.basemenu;
				this.$forceUpdate();
				this.finished = false;
				this.loading = true;
				this.onLoad();
			},
			tochange(e, j, i) {
				this.menu[i].list.forEach((el) => {
					if (j == el.name) {
						el.show = true;
					} else {
						el.show = false;
					}
				});
				this.$set(this.menu[i], "child", j);
				console.log(e, j);
				if (JSON.stringify(this.formdata).indexOf(e) != -1) {
					this.formdata[e] = j;
				} else {
					var a = { [e]: j };
					this.formdata = Object.assign(a, this.formdata);
				}
				this.onRefresh();
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},

			goopen(e) {
				let url =
					"https://opensea.io/assets/matic/0xb5e200d739bc792e273b2beb6dea9fd918ede79b/" +
					e;
				window.open(url);
			},
			onRefresh() {
				this.formdata.Page = 1;
				this.finished = false;
				this.loading = true;
				this.onLoad();
			},
			onLoad() {
				// if (this.formdata.Page == 1) {
				//    this.list = [];
				//    this.refreshing = false;
				// }
			},
			getMenu() {
				this.$get({
					url: "menu",
					data: {},
					callback: (res) => {
						this.$nextTick(function () {
							if (res.data) {
								this.menu = res.data;
								this.basemenu = res.data;
							}
						});
					},
				});
			},
			tochild(e, s) {
				this.menu.forEach((el, i) => {
					if (e == i) {
						el.show = !s;
					} else {
						el.show = false;
					}
				});
				this.$forceUpdate();

				// this.$set(this.menu[e],"show",!s)
			},
			toshowList() {
				var address = this.$store.getters.userWallet;
				if (!address) {
					this.wallet();
					return;
				}
				walletOfOwner(this.$store.getters.userWallet).then((res) => {
					// console.log("res",res);
					this.basedata = res;
					if (res.length > 0) {
						this.showlist = true;
						res.forEach((el) => {
							this.getUrl(el);
						});
						// this.list.length=res.length
						// console.log(this.list);
					}
				});
			},
			closes() {
				this.showlist = false;
			},
			getUrl(e) {
				tokenURI(e).then((res) => {
					let regs = new RegExp("https://ipfs.io/", "g");
					var urls = res.replace(
						regs,
						"https://www.cupidlovemetaverse.com/"
					);
					this.$axios({
						method: "GET",
						url: urls,
					}).then((response) => {
						// console.log(response.data);
						let reg = new RegExp("ipfs://", "g");
						var url = response.data.image.replace(
							reg,
							"https://www.cupidlovemetaverse.com/ipfs/"
						);
						this.mylist[e] = url;

						this.$forceUpdate();
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	$t-mf: "Arial";

	.el-icon-arrow-down:before {
		content: "";
	}
	.main {
		background: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
		width: 100%;
		min-width: 1200px;
		max-width: 100%;
		.htop {
			height: 70px;
		}
		.demo_box1 {
			width: 1200px;
			margin: 0 auto;
			min-height: 713px;
			background: #fff;
			.box1_top {
				display: flex;
				text-align: left;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				line-height: 50px;

				align-items: center;
				.img {
					width: 24px;
					height: 24px;
					background: url("../assets/indeximg/point.png");
					background-size: 100% 100%;
					margin-right: 12px;
				}
				div > span {
					color: #0052da;
					cursor: pointer;
				}
			}
			.box1_bottom {
				width: 1200px;
				height: 219px;
				background: #ffffff;
				border: 1px solid #f3f3f3;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 40px 67px;
				justify-content: center;
				align-items: center;
				.bot_item {
					display: flex;
					border-bottom: 1px solid #eee;
					width: 100%;
					padding: 10px 0;
					.tap_title {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						height: 40px;
						line-height: 40px;
						width: auto;
						min-width: 60px;
						padding-right: 20px;
						text-align: center;
						border-right: 1px solid #eee;
						cursor: default;
						white-space: nowrap;
					}
					.tap {
						display: flex;
						align-content: center;
						justify-content: center;
						cursor: pointer;

						div {
							width: 100px;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #666666;
							// padding:0 10px;
							text-align: center;
							border: 1px solid transparent;
							border-radius: 2px;
							padding: 10px;
							height: 20px;
							line-height: 20px;
						}
						.tab_act {
							border: 1px solid #0052da;
							border-radius: 2px;
							text-align: center;
							color: #0052da;
							text-align: center;
						}
					}
				}
			}
			.box2 {
				width: 1200px;
				margin: 0 auto;
				margin-top: 47px;
				.box1_con {
					width: 1200px;
					margin: 0 auto;
					display: flex;
					justify-content: left;
					flex-wrap: wrap;
					text-align: center;
					.con_item {
						display: flex;
						flex-direction: column;
						width: 391px;
						height: 376px;
						border: 1px solid #e0e0e0;
						margin-bottom: 20px;
						.item_img {
							width: 390px;
							height: 257px;
							img {
								width: 390px;
								height: 257px;
							}
							// background: red;
						}
						.item_title {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							text-align: left;
							padding-left: 20px;
							padding-top: 10px;
						}
						.item_con {
							text-align: left;
							padding-left: 20px;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							padding-right: 15px;
							margin-top: 10px;
							height: 68px;
							//           display:block;
							//  white-space:nowrap;
							//  overflow:hidden;
							//  text-overflow:ellipsis;
						}
						.item_box {
							display: flex;
							padding-left: 20px;
							margin: 10px 0;
							div {
								width: 56px;
								border: 1px solid #dadada;
								border-radius: 2px;
								text-align: center;
								margin-right: 5px;
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #666666;
							}
						}
					}
					.con_item:nth-child(3n-1) {
						margin: 0 10px;
					}
					.con_item:hover {
						box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
						transform: scale(1.02) translateZ(0);
					}
				}
				.box1_cons {
					margin: 0 auto;
				}
			}
		}
		.page {
			margin: 50px 0;
		}
	}
	.h5main {
		width: 100%;
		min-width: 100%;
		.htop {
			height: 135px;
		}
		.demo_box1 {
			width: 100%;
			.box1_top {
				line-height: 88px;
				font-size: 26px;
				padding-left: 10px;
			}
			.box1_bottom {
				width: 100%;
				padding: 10px;
				height: auto;
				.bot_item {
					padding: 10px 0;
					.tap_title {
						width: auto;
						font-size: 26px;
						padding-right: 20px;
					}
					.tap {
						flex: 1;
						flex-wrap: wrap;
						justify-content: flex-start;
						div {
							width: auto;
							font-size: 26px;
							min-width: 100px;
							padding: 10px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.box2 {
				width: 100%;
				.box1_con {
					width: 100%;
					.con_item {
						width: 100%;
						height: auto;
						margin: 20px;
						.item_img {
							width: 100%;
							height: 100%;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.item_title {
							font-size: 28px;
						}
						.item_con {
							font-size: 26px;
							margin: 10px 0;
							line-height: 42px;
						}
						.item_box {
							margin: 20px 0;
							div {
								margin-right: 20px;
								padding: 10px;
								width: auto;
								font-size: 26px;
							}
						}
					}
					.con_item:nth-child(3n-1) {
						margin: 20px;
					}
				}
			}
		}
		.page {
			margin-top: 30px;
			margin-bottom: 40px;
			padding: 30px;
			background: #ffffff;
			::v-deep {
				i,
				li {
					font-size: 32px;
					padding: 0 20px;
				}
			}
		}
	}
</style>
